import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import HeroImage from '../../images/use-cases/heroes/psps.png';
import PageComponent from '../../components/use-cases/PageComponent';


/*
 * Constants
 */
const features = [
    {
        key: 'overview',
        logoImg: <StaticImage src="../../images/use-cases/features/overview.png" placeholder="none" alt="" />,
        title: <Trans>Free distribution channel for your services.</Trans>,
        name: <Trans>Channels</Trans>,
        content: (
            <Trans>
                By working with us, your services will be readily available on the Switch Platform, meaning any
                merchants we are working with can enable your services without any developments, turning Switch into a
                flexible, cost-free distribution channel for your services.
            </Trans>
        ),
        link: {
            url: '/channels',
            text: <Trans>Discover our Channels</Trans>,
        },
    },
    {
        key: 'analytics',
        logoImg: <StaticImage src="../../images/use-cases/features/analytics.png" placeholder="none" alt="" />,
        title: <Trans>Grow without worries.</Trans>,
        name: <Trans>Analytics</Trans>,
        content: (
            <Trans>
                Large volume businesses have so much data that it can be a costly and challenging engineering task to
                scale its infrastructure. We use state-of-the-art technology to handle and process Big Data, so you
                won&apos;t have to.
            </Trans>
        ),
        link: {
            url: '/product/analytics',
            text: <Trans>Discover Analytics</Trans>,
        },
    },
    {
        key: 'whitelabel',
        logoImg: <StaticImage src="../../images/use-cases/features/whitelabel.png" placeholder="none" alt="" />,
        title: <Trans>Keep the brand aesthetic with a White Label Solution.</Trans>,
        name: <Trans>Whitelabel</Trans>,
        content: (
            <Trans>
                Our products can be rebranded to match your identity. With this, you can uniformize your resources and
                customize your sub-merchants to match their own visual identity.
            </Trans>
        ),
        link: {
            url: 'https://switchpayments.com/docs/whitelabel',
            text: <Trans>Discover our Whitelabel solution</Trans>,
        },
    },
];


/*
 * Public Elements
 */
const MerchantsUseCasePage = ({data}) => {
    const {t} = useTranslation();

    const title = t('PSPs');
    const quantifier = t('dozens');
    const subtitle = t(
        'More than 50 PSPs are optimizing their payments operations with Switch. See what we can do to help you.',
    );

    const metatags = {
        title: t('White Label Payment Gateway for Payment Providers - Switch'),
        description: t('White label payment gateway solution for PSPs, ISVs, and acquirers. Customize your accounts to '
            + 'match a custom visual identity.'),
        // eslint-disable-next-line i18next/no-literal-string
        image: '/website/metatags/use-cases-psps.png',
    };

    return (
        <PageComponent
            data={data}
            image={HeroImage}
            title={title}
            quantifier={quantifier}
            subtitle={subtitle}
            features={features}
            metatags={metatags}
        />
    );
};

MerchantsUseCasePage.propTypes = {
    data: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default MerchantsUseCasePage;
export const query = graphql`
    query($language: String!, $useCaseType: String!) {
        ...UseCaseFragment
    }
`;
